import $ from "jquery"

export function commerseHeader() {
  $(".menu_inner li.has-children > a").on("click", function CommerseHeaderEvent(event){
    event.preventDefault()
    $(".dropdown-menu").animate({ scrollTop: "0px" })
    $(this).next(".dropdown-menu")
      .addClass("open")
    $("#menu_wrapper > .container").animate({ scrollTop: "0px" })
    $("#menu_wrapper > .container").css("overflow-y", "hidden")
  })
  $("#menu_wrapper").on("hidden.bs.collapse", () => {
    $(".dropdown-menu").removeClass("open")
    $("#menu_wrapper > .container").css("overflow-y", "auto")
  })
  $(".dropdown-header").on("click", function dropDownHeaderClosest(){
    $(this).closest(".dropdown-menu")
      .removeClass("open")
  })
  $(".dropdown-header.layer-1").on("click", function DropDownHeaderLayer(){
    $(this).closest(".dropdown-menu")
      .removeClass("open")
    $("#menu_wrapper > .container").css("overflow-y", "auto")
  })
}
